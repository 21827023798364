import '@Application/Bootstrap';

import autoload from '@123/druid/dist/Framework/Decorators/Autoload';
import component from '@123/druid/dist/Framework/Decorators/Component';
import {inject} from '@123/druid/dist/Framework/Decorators/Inject';
import View from '@123/druid/dist/Framework/View/View';
import QuickFilter from '@Module/Group/Component/QuickFilter/QuickFilter';

@autoload
@component
export default class GroupListModuleView extends View {
    constructor(@inject(QuickFilter) quickFilter: QuickFilter) {
        super();
        this.register(quickFilter);
    }
}
