import type {QuickFilterMeasureItem} from '@Module/Group/Component/QuickFilter/Types/QuickFilterMeasureItem';

declare global {
    interface Window {
        Measure?: {
            Manager?: {
                instance?: {
                    quickFilter(quickFilterMeasureItem: QuickFilterMeasureItem): Promise<void>;
                };
            };
            CustomProperties: {
                setQuickFilterValue(value: string): void;
                setQuickFilterTotalPositions(value: number): void;
            };

            event(category: string, action: string, label?: string, value?: string | number, nonInteraction?: boolean): Promise<void>;
        };
    }
}

export default class Measure {
    public static event(category: string, action: string, label?: string, value?: string | number, nonInteraction?: boolean): Promise<void> | void {
        return window.Measure?.event(category, action, label, value, nonInteraction);
    }

    public static quickFilter(quickFilterMeasureItem: QuickFilterMeasureItem): Promise<void> | void {
        return window.Measure?.Manager?.instance?.quickFilter(quickFilterMeasureItem);
    }

    public static setQuickFilterValue(value: string): void {
        return window.Measure?.CustomProperties.setQuickFilterValue(value);
    }

    public static setQuickFilterTotalPositions(value: number): void {
        return window.Measure?.CustomProperties.setQuickFilterTotalPositions(value);
    }
}
