import type ViewAccessor from '@123/druid/dist/Framework/View/ViewAccessor';
import debounce from '@123/druid/dist/Utility/Decorator/Debounce';
import type ApplicationConfig from '@Application/ApplicationConfig';
import QuickFilterChangedMessage from '@Module/Group/Component/QuickFilter/Message/QuickFilterChangedMessage';
import {zeroFill} from '@Module/Group/Component/QuickFilter/Utility/Helpers';
import type QuickFilterInput from '@Module/Group/Component/QuickFilter/WebComponent/QuickFilterInput';
import Measure from '@Service/LegacyBridge/Measure/Measure';
import type MessageBus from '@Service/Message/MessageBus';
import type Scrollable from '@Service/Scrollable/Scrollable';
import type {QuickFilterMeasureItem} from '@Module/Group/Component/QuickFilter/Types/QuickFilterMeasureItem';

// Note: the regex used here, is also used in drshop: @see: drshop/src/View/Group/GroupHelper.php
// so, if you need to change it here, you probably also need to change it there.
const inputSanitizeRegex = /[\s-]+/g;

export default class QuickFilterHandler {
    public static readonly HIDE_IF_NO_RESULTS_ROLE = 'quickfilter-hide-if-no-results';
    public static readonly SHOW_IF_NO_RESULTS_ROLE = 'quickfilter-show-if-no-results';
    public static readonly HIDE_IF_ACTIVE_ROLE     = 'quickfilter-hide-if-active';
    public static readonly TARGET_ROLE             = 'quickfilter-target';
    public static readonly GROUP_ROLE              = 'quickfilter-target-group';

    public static readonly NO_RESULTS_EVENT_ACTION     = 'zero resultgroups';
    public static readonly FILTER_EDITING_EVENT_ACTION = 'on editing or deleting';
    public static readonly GROUP_CLICKED_EVENT_ACTION  = 'group clicked';

    private filter: string         = '';
    private previousFilter: string = '';
    private measureId: string      = '';
    private scrollTarget?: HTMLElement;
    private totalPositions: number = 0;

    private targets: {isVisible: boolean; element: HTMLElement}[] = [];

    constructor(
        private accessor: ViewAccessor,
        private readonly scroller: Scrollable,
        private readonly applicationConfig: ApplicationConfig,
        private messageBus?: MessageBus
    ) {
    }

    public attach(): void {
        this.accessor.onMounted(() => {
            const element  = this.accessor.getElement();
            this.measureId = element.datamap().getString('measureId', 'unknown');

            this.prepareTargets(element);

            void element.queryRolePromise<QuickFilterInput>('quickfilter')
                .then(quickFilterInput => this.applyFilter(quickFilterInput.value));

            void element.queryRolePromise<HTMLElement>('quickfilter-scroll-target')
                .then(scrollTargetElement => this.scrollTarget = scrollTargetElement);

            element.addDelegateListener('change', '[data-role=quickfilter]', (evt) => {
                this.applyFilter((<QuickFilterInput>evt.delegateTarget).value);
            });

            element.addDelegateListener('click', '[data-role~=quickfilter-target]', (evt) => {
                const measure: {url: string; title: string; type: string} = JSON.parse(evt.delegateTarget.attributeMap().getString('data-measure'));

                const allVisibleTargets = this.targets.filter(target => target.isVisible);
                const position          = allVisibleTargets.findIndex(elem => elem.element === evt.delegateTarget) + 1;

                this.sendMeasure(`${QuickFilterHandler.GROUP_CLICKED_EVENT_ACTION} - ${measure.type}`, measure.url, measure.title, position);
            });
        });
    }

    private prepareTargets(container: HTMLElement): void {
        this.targets = Array.from(container.querySelectorAll('[data-role~=quickfilter-target]')).map(target => ({
            isVisible: true,
            element: <HTMLElement>target
        }));
    }

    /**
     * Will toggle the 'u-hidden' class on elements, based on whether the data-filter property of that element matches the filter
     *
     * @param {string} filter - the value by which elements must be filtered
     * @private
     */
    @debounce(100, false)
    private applyFilter(filter: string): void {
        filter = filter.toLowerCase().replace(inputSanitizeRegex, '');
        if (filter === this.previousFilter) {
            return;
        }

        Measure.setQuickFilterValue(filter);

        this.toggleTargets(filter);
        const hasResults = this.toggleGroups();
        this.toggleOtherElements(hasResults, filter);
        this.resetScroll();

        this.filter = filter;

        // Send event when user deletes or replaces characters within the QuickFilter.
        if (filter.length <= this.previousFilter.length) {
            this.sendMeasure(QuickFilterHandler.FILTER_EDITING_EVENT_ACTION);
        }

        // Send event when no results have been found
        if (hasResults === false) {
            this.sendMeasure(QuickFilterHandler.NO_RESULTS_EVENT_ACTION);
        }

        this.previousFilter = filter;

        void this.messageBus?.emit(new QuickFilterChangedMessage());
    }

    /**
     * sends a measure with a QuickFilterMeasureItem object
     *
     * @param {string} action - the name of the action/trigger
     * @param {string} linkUrl - the url of the group
     * @param {string} linkText - the title of the group
     * @param {number} position - the position clicked inside the quick filter
     * @private
     */
    private sendMeasure(action: string, linkUrl?: string, linkText?: string, position?: number): void {
        const quickFilterMeasureItem: QuickFilterMeasureItem = {
            category: this.measureId,
            action: action,
            label: this.filter,
            totalPositions: zeroFill(this.totalPositions, 4)
        };

        if (linkUrl) {
            quickFilterMeasureItem.linkUrl = linkUrl;
        }

        if (linkText) {
            quickFilterMeasureItem.linkText = linkText;
        }

        if (position) {
            quickFilterMeasureItem.position = position === 0 ? '' : zeroFill(position, 4);
        }

        void Measure.quickFilter(quickFilterMeasureItem);
    }

    /**
     * hides elements based on whether it complies with the filter
     *
     * @param {string} filter - show/hide every 'TARGET_ROLE' element based on the filter value
     * @private
     */
    private toggleTargets(filter: string): void {
        this.targets.forEach((target) => {
            // test the filter against data-filter on the element
            const visible    = target.element.datamap().getString('filter').toLowerCase().includes(filter);
            target.isVisible = visible;
            target.element.classList.toggle('u-hidden', visible === false);
        });

        this.totalPositions = this.targets.filter(target => target.isVisible).length;
        Measure.setQuickFilterTotalPositions(this.totalPositions);
    }

    /**
     * Hides groups if all their targets are hidden.
     *
     * @returns {boolean} - true if there are still visible groups left
     * @private
     */
    private toggleGroups(): boolean {
        // targets should fall within a group. If the group has no visible targets, it will be hidden itself.
        const groups = this.accessor.getElement().queryRoleAll(QuickFilterHandler.GROUP_ROLE);
        // we also need to know if all groups are hidden. We keep track of that here. It is later used to show/hide other elements in the page
        let hasResults = false;
        groups.forEach((group) => {
            const targetsInGroup = Array.from<HTMLElement>(group.queryRoleAll(QuickFilterHandler.TARGET_ROLE));
            // if any of the targets has the u-hidden class, this means that some are still visible. In this case we do not hide the entire group
            const someTargetsAreVisible = targetsInGroup.some(target => target.classList.contains('u-hidden') === false);
            group.classList.toggle('u-hidden', someTargetsAreVisible === false);
            if (someTargetsAreVisible === true) {
                hasResults = true;
            }
        });

        return hasResults;
    }

    /**
     * Hides various elements based on the state of the filter.
     * If there are no results, some elements must be hidden, others must be shown.
     * If there is an active filter (i.e. it's not an empty string), some elements must also be hidden.
     *
     * @param {boolean} hasResults - true if there are still elements that met the filter criterium
     * @param {string} currentFilter - the value of the current filter. Use empty string if there is currently no filter active.
     * @private
     */
    private toggleOtherElements(hasResults: boolean, currentFilter: string): void {
        this.accessor.getElement()
            .queryRoleAll(QuickFilterHandler.HIDE_IF_NO_RESULTS_ROLE)
            .forEach(element => element.classList.toggle('u-hidden', hasResults === false));

        this.accessor.getElement()
            .queryRoleAll(QuickFilterHandler.SHOW_IF_NO_RESULTS_ROLE)
            .forEach(element => element.classList.toggle('u-hidden', hasResults));

        this.accessor.getElement()
            .queryRoleAll(QuickFilterHandler.HIDE_IF_ACTIVE_ROLE)
            .forEach(element => element.classList.toggle('u-hidden', currentFilter !== ''));
    }

    private resetScroll(): void {
        if (this.scrollTarget === undefined) {
            return;
        }

        this.scroller.setBounds(this.applicationConfig.getScrollBounds());
        this.scroller.scrollElement(this.scrollTarget, {block: 'start', behavior: 'smooth'});
    }
}
